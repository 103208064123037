var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "900" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
            _vm._v(" Monthly Inverter Adjustment Factors "),
          ]),
          !_vm.showConfirmation
            ? _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.tableData,
                      "items-per-page": 15,
                      dense: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function (ref) {
                            var item = ref.item
                            var rowIndex = ref.index
                            return [
                              _c(
                                "tr",
                                [
                                  _c("td", [_vm._v(_vm._s(item.month))]),
                                  _vm._l(
                                    item.factors,
                                    function (factor, colIndex) {
                                      return _c(
                                        "td",
                                        {
                                          key: colIndex,
                                          on: {
                                            click: function ($event) {
                                              return _vm.startEditing(
                                                rowIndex,
                                                colIndex,
                                                factor
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.isEditingCell(rowIndex, colIndex)
                                            ? _c("v-text-field", {
                                                staticClass: "edit-textbox",
                                                attrs: { dense: "", solo: "" },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.validateAndFormatInput(
                                                      $event,
                                                      rowIndex,
                                                      colIndex
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.stopEditing(
                                                      rowIndex,
                                                      colIndex,
                                                      item.factors[colIndex]
                                                    )
                                                  },
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.stopEditing(
                                                      rowIndex,
                                                      colIndex,
                                                      item.factors[colIndex]
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.factors[colIndex],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.factors,
                                                      colIndex,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.factors[colIndex]",
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(_vm._s(factor)),
                                              ]),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      141486368
                    ),
                  }),
                ],
                1
              )
            : _c("v-card-text", [
                _c("p", [
                  _vm._v(
                    " Do you want to queue the inverter estimate recast for the past 12 months before closing? This process may take up to 5 minutes to complete. "
                  ),
                ]),
              ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.showConfirmation
                ? [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "red", text: "" },
                        on: { click: _vm.confirmRecast },
                      },
                      [_vm._v("Yes, Recast")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "grey", text: "" },
                        on: { click: _vm.cancelRecast },
                      },
                      [_vm._v("No, Just Close")]
                    ),
                  ]
                : [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "font-weight-bold text-xs btn-danger bg-gradient-danger",
                        on: { click: _vm.promptConfirmation },
                      },
                      [_vm._v(" Close ")]
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }