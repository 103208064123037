var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "card-shadow border-radius-xl" },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c("v-data-table", {
                ref: "datatable",
                staticClass: "table",
                attrs: {
                  headers: _vm.projectionsHeaders,
                  items: _vm.sites,
                  "item-key": "id",
                  search: _vm.search,
                  "custom-filter": _vm.customSearch,
                  page: _vm.page,
                  "hide-default-footer": "",
                  "items-per-page": _vm.itemsPerPage,
                  "mobile-breakpoint": "0",
                },
                on: {
                  "current-items": _vm.getFiltered,
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "page-count": function ($event) {
                    _vm.pageCount = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", height: "80" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "9" } },
                                  [
                                    _c(
                                      "v-text-field",
                                      {
                                        staticClass:
                                          "\n                     input-style\n                     font-size-input\n                     text-light-input\n                     placeholder-light\n                     input-icon\n                   ",
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          flat: "",
                                          filled: "",
                                          solo: "",
                                          height: "43",
                                          placeholder: "Search",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "prepend-inner" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "material-icons-round mt-n2",
                                                attrs: {
                                                  color: "#adb5bd",
                                                  size: "16",
                                                },
                                              },
                                              [_vm._v("search")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                 font-weight-bold\n                 text-xs\n                 btn-primary\n                 bg-gradient-primary\n               ",
                                attrs: {
                                  ripple: false,
                                  elevation: 0,
                                  height: "43",
                                },
                                on: { click: _vm.exportToCsv },
                              },
                              [_vm._v(" Export Report ")]
                            ),
                            _vm._v("    "),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "\n                 font-weight-bold\n                 text-xs\n                 btn-primary\n                 bg-gradient-primary\n               ",
                                attrs: {
                                  ripple: false,
                                  disabled: _vm.loading,
                                  elevation: 0,
                                  height: "43",
                                },
                                on: { click: _vm.exportProjectionsCsv },
                              },
                              [
                                _vm.loading
                                  ? [
                                      _c("v-progress-circular", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          indeterminate: "",
                                          color: "white",
                                          size: "20",
                                        },
                                      }),
                                      _vm._v(" Loading... "),
                                    ]
                                  : [_vm._v(" Export Projections ")],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { width: "1" } }),
                            _vm._v(" "),
                            _c("th", { attrs: { colspan: "1" } }, [
                              _vm._v("Last Month"),
                            ]),
                            _c("th", { attrs: { colspan: "1" } }, [
                              _vm._v("Last 3 Months"),
                            ]),
                            _c("th", { attrs: { colspan: "1" } }, [
                              _vm._v("Last 12 Months"),
                            ]),
                            _c("th", { attrs: { colspan: "1" } }),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center ms-2" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "py-auto",
                                attrs: { to: "/sites/" + item.id },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-sm font-weight-normal text-body",
                                    staticStyle: { "text-decoration": "none" },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.m_projected_health",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "site-wider-slot" },
                          [
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: _vm.getColor(
                                      item.m_projected_health
                                    ),
                                    dark: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Math.round(item.m_projected_health)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.three_month_projected_health",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "site-wider-slot" },
                          [
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: _vm.getColor(
                                      item.three_month_projected_health
                                    ),
                                    dark: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Math.round(
                                          item.three_month_projected_health
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.twelve_month_projected_health",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-progress-linear",
                          {
                            staticStyle: { width: "100px" },
                            attrs: {
                              value: item.twelve_month_projected_health,
                              height: "15",
                              "background-color": _vm.progressBackground(
                                item.twelve_month_projected_health
                              ),
                              color: _vm.progressBarColor(
                                item.twelve_month_projected_health
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(
                                  Math.ceil(item.twelve_month_projected_health)
                                ) + "%"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.monitoring_url",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      item.monitoring_url
                                        ? _c(
                                            "img",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  src: require("@/assets/img/" +
                                                    item.monitoring_portal +
                                                    ".png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.openWindow(
                                                      item.monitoring_url
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Monitoring Link")])]
                        ),
                        [
                          !item.monitoring_url
                            ? _c("v-icon", { attrs: { disabled: "" } }, [
                                _vm._v("mdi-web-box"),
                              ])
                            : _vm._e(),
                        ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "card-padding" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "text-body me-3 text-sm" }, [
                        _vm._v("Items per page:"),
                      ]),
                      _c("v-text-field", {
                        staticClass:
                          "\n               font-size-input\n               placeholder-lighter\n               text-color-light\n               input-alternative input-focused-alternative input-icon\n             ",
                        attrs: {
                          "hide-details": "",
                          type: "number",
                          outlined: "",
                          min: "-1",
                          max: "15",
                          "background-color": "rgba(255,255,255,.9)",
                          color: "rgba(0,0,0,.6)",
                          light: "",
                          value: _vm.itemsPerPage,
                          placeholder: "Items per page",
                        },
                        on: { input: _vm.updateItemsPerPage },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto d-flex justify-end",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("v-pagination", {
                        staticClass: "pagination",
                        attrs: {
                          "prev-icon": "fa fa-angle-left",
                          "next-icon": "fa fa-angle-right",
                          color: "#D81B60",
                          length: _vm.pageCount,
                          circle: "",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }