<template>
  <div>
    <v-container fluid class="px-6 py-6">  
      <v-row >
        <v-col cols="12">
          <h4 v-if="loading">
            <v-progress-circular
              indeterminate
            ></v-progress-circular>
          </h4>
          <projections-portal v-else v-bind:projSites="projSites" v-bind:projectionsHeaders="projectionsHeaders"></projections-portal>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SalesCountry from "./Widgets/SalesCountry.vue";
import CardWebsiteViews from "./Widgets/CardWebsiteViews.vue";
import CardDailySales from "./Widgets/CardDailySales.vue";
import CardCompletedTasks from "./Widgets/CardCompletedTasks.vue";
import HighAlertSite from './Widgets/HighAlertSite.vue';
import CardOpenCases from './Widgets/CardOpenCases.vue';
import DatatableSites from '../Applications/Widgets/DatatableSites.vue';
import ProjectionsPortal from './ProjectionsPortal.vue';
import CardThirtyDayHealth from './Widgets/CardThirtyDayHealth.vue';
import CardThirtyDayPerf from './Widgets/CardThirtyDayPerf.vue';
import ProblemInverter from './Widgets/ProblemInverter.vue';
import CardMissingKwhData from './Widgets/CardMissingKwhData.vue';
import MediumAlertSite from './Widgets/MediumAlertSite.vue';
import AllSites from './Widgets/AllSites.vue';
import Service from "@/services/Service.js";

export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    CardWebsiteViews,
    CardDailySales,
    CardCompletedTasks,
    HighAlertSite,
    CardOpenCases,
    DatatableSites,
    CardThirtyDayHealth,
    CardThirtyDayPerf,
    ProblemInverter,
    CardMissingKwhData,
    ProjectionsPortal,
    MediumAlertSite,
    AllSites
  },
  beforeMount() {
    
  },
  data() {
    return {
      checkFreqFilterAll: 'all',
      tabChangeFlag: {},
      selectDate: null,
      everySite: null,
      componentProps: {},
      loading: false,
      checkFrequenciesToDisplay: null,
      displayAllCheckFrequencies: false,
      yesterdayMoment: null,
      metricsEndDateMoment: null,
      displayMetricsEndDate: null,
      projSites: null,
      todaysDate: null,
      dateToPass: null,
      allCheckFrequencies: [
        {
          value: "daily",
          text: "Daily",
        },
        {
          value: "weekly",
          text: "Weekly",
        },
        {
          value: "monthly",
          text: "Monthly",
        },
      ],
      highAlerts: {},
      mediumAlerts: {},
    };
  },
  created: function () {
    this.todaysDate = this.$moment()
    this.dateToPass = this.todaysDate.format("YYYY-MM-DD");
    this.loadData();
    this.loadHeaders();
  },
  mounted() {
    document.title = "Projections Portal"
  },
  methods: {
    loadData() {
      this.loading = true;
      Service.getSiteProjectionMetrics(this.dateToPass)
        .then((response) => {
          this.projSites = response.data
          NProgress.done();
          this.loading = false;
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        })
    },
    loadHeaders() {
      if (!this.todaysDate) {
        console.error("todaysDate is not set or invalid.");
        return;
      }

      // Parse todaysDate into a Date object if it's not already
      const selectedDate = new Date(this.todaysDate);

      // Ensure the date is valid
      if (isNaN(selectedDate.getTime())) {
        console.error("Invalid todaysDate.");
        return;
      }

      // Helper to format dates as "MMM YYYY"
      const formatMonthYearFull = (date) =>
        `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

      // Helper to format months and year as "MMMYY"
      const formatMonthYearCompact = (date) =>
        `${monthNames[date.getMonth()]}${date.getFullYear() % 100}`;

      // Array of month names
      const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

      // Get the previous month, formatted as "MMM YYYY"
      const lastMonthDate = new Date(selectedDate);
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      const lastMonth = formatMonthYearFull(lastMonthDate);

      // Get the last three months (excluding the current month), formatted as "MMM-MMM-MMM"
      const threeMonths = [];
      for (let i = 3; i > 0; i--) {
        const monthDate = new Date(selectedDate);
        monthDate.setMonth(monthDate.getMonth() - i);
        threeMonths.push(monthNames[monthDate.getMonth()]);
      }
      const lastThreeMonths = threeMonths.join("-");

      // Get the last 12 months (excluding the current month), formatted as "MMM YYYY - MMM YYYY"
      const firstMonthDate = new Date(selectedDate);
      firstMonthDate.setMonth(firstMonthDate.getMonth() - 12);
      const firstMonthYear = formatMonthYearFull(firstMonthDate);

      const lastMonthYear = formatMonthYearFull(lastMonthDate);
      const last12Months = `${firstMonthYear} - ${lastMonthYear}`;

      this.projectionsHeaders = [
        {
          text: "Name",
          align: "start",
          cellClass: "border-bottom",
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          width: 382
        },
        {
          text: lastMonth,
          value: "m_projected_health",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: lastThreeMonths,
          value: "three_month_projected_health",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: last12Months,
          value: "twelve_month_projected_health",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Monitoring URL",
          value: "monitoring_url",
        },
      ]
    }
  },
};
</script>
<style>
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
</style>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>