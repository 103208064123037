<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Monthly Inverter Adjustment Factors
      </v-card-title>

      <v-card-text v-if="!showConfirmation">
        <v-data-table
          :headers="headers"
          :items="tableData"
          :items-per-page="15"
          dense
          class="elevation-1"
        >
          <template #item="{ item, index: rowIndex }">
            <tr>
              <td>{{ item.month }}</td>
              <td
                v-for="(factor, colIndex) in item.factors"
                :key="colIndex"
                @click="startEditing(rowIndex, colIndex, factor)"
              >
                <!-- Show input if this cell is being edited -->
                <v-text-field
                  v-if="isEditingCell(rowIndex, colIndex)"
                  v-model="item.factors[colIndex]"
                  dense
                  solo
                  class="edit-textbox"
                  @input="validateAndFormatInput($event, rowIndex, colIndex)"
                  @blur="stopEditing(rowIndex, colIndex, item.factors[colIndex])"
                  @keyup.enter="stopEditing(rowIndex, colIndex, item.factors[colIndex])"
                />
                <!-- Show the value if not being edited -->
                <span v-else>{{ factor }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- Confirmation Section -->
      <v-card-text v-else>
        <p>
          Do you want to queue the inverter estimate recast for the past 12 months before closing?
          This process may take up to 5 minutes to complete.
        </p>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="showConfirmation">
          <v-btn color="red" text @click="confirmRecast">Yes, Recast</v-btn>
          <v-btn color="grey" text @click="cancelRecast">No, Just Close</v-btn>
        </template>
        <template v-else>
          <v-btn
            class="font-weight-bold text-xs btn-danger bg-gradient-danger"
            @click="promptConfirmation"
          >
            Close
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";

Vue.use(VueToast);

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    inverterData: {
      type: Array,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: this.value,
      showConfirmation: false, // Controls whether the confirmation prompt is shown
      editingCell: { row: null, col: null }, // Tracks which cell is being edited
    };
  },
  created: function () {
    console.log(`site id = ${this.siteId}`);
  },
  computed: {
    headers() {
      return [
        { text: "Month", value: "month" },
        ...this.inverterData.map((inverter) => ({
          text: inverter.name,
          value: `inv${inverter.id}`,
        })),
      ];
    },
    tableData() {
      return Array.from({ length: 12 }, (_, i) => {
        const monthIndex = i + 1;
        return {
          month: this.getMonthName(monthIndex),
          factors: this.inverterData.map((inverter) => {
            const factorEntry = inverter.factors.find(
              (f) => f.month === monthIndex
            );
            return factorEntry ? factorEntry.factor : null;
          }),
        };
      });
    },
  },
  methods: {
    validateAndFormatInput(event, row, col) {
      const value = event;

      // Allow only valid floating-point numbers
      if (!/^\d*\.?\d*$/.test(value)) {
        // Remove the last invalid character
        event = value.slice(0, -1);
        this.tableData[row].factors[col] = event;
        Vue.$toast.warning("Only numeric values are allowed!", {
          position: "top-right",
        });
      }
    },
    promptConfirmation() {
      this.showConfirmation = true; // Show the confirmation prompt
    },
    confirmRecast() {
      this.recastInverterEstimates();
      this.showConfirmation = false; // Hide the confirmation prompt
      this.dialog = false; // Close the dialog
    },
    cancelRecast() {
      this.showConfirmation = false; // Hide the confirmation prompt
      this.dialog = false; // Close the dialog
    },
    closeDialog() {
      // Show the confirmation prompt instead of closing the dialog immediately
      this.showConfirmation = true;
    },
    getMonthName(month) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      return monthNames[month - 1];
    },
    startEditing(row, col, value) {
      this.editingCell = { row, col };
      console.log(
        `Started editing cell at row ${row}, column ${col}. Current value: ${value}`
      );
    },
    stopEditing(row, col, newValue) {
      // debugger;
      console.log(
        `Stopped editing cell at row ${row}, column ${col}. New value: ${newValue}`
      );
      this.editingCell = { row: null, col: null };

      // const siteId = this.siteId; // Replace with your actual site ID
      const inverterId = this.headers[col+1].value.replace('inv', ''); // Extract inverter ID from header
      const month = this.tableData[row].month; // Get the month from the row
      // Ensure the factor is a float
      // Validate that the new value is a floating-point number
      const factor = parseFloat(newValue);
      if (isNaN(factor) || factor < 0) {
        Vue.$toast.error("Invalid input. Please enter a valid number", {
          position: "top-right",
        });
        return;
      }
      this.updateInverterFactor(inverterId, month, factor);
    },
    isEditingCell(row, col) {
      return this.editingCell.row === row && this.editingCell.col === col;
    },
    recastInverterEstimates() {
      const siteId = this.siteId;

      Service.recastInverterEstimates(siteId)
        .then((response) => {
          Vue.$toast.success(
            "Recast process has been queued successfully. Please wait approximately 5 minutes for the updates to reflect.",
            {
              position: "top-right",
            }
          );
          console.log("Recast response:", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error recasting inverter estimates.", {
            position: "top-right",
          });
          console.error("Recast error:", error);
        });
    },
    updateInverterFactor(inverterId, month, factor) {
      const params = { month, factor };

      Service.updateInverterFactor(inverterId, params)
        .then((response) => {
          Vue.$toast.success("Inverter factor updated successfully", {
            position: "top-right",
          });
          console.log("Response:", response.data);
        })
        .catch((error) => {
          Vue.$toast.error("Error updating inverter factor", {
            position: "top-right",
          });
          console.error("Error:", error);
        });
    },
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
<style>
.edit-textbox {
  max-width: 80px; /* Set a smaller width for the text box */
  font-size: 14px; /* Reduce font size */
  height: 30px; /* Set a smaller height */
  padding: 4px; /* Reduce padding */
}
</style>
