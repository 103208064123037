<template>
  <div>
    <Grid
      ref="grid"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
      :selected-field="selectedField"
      @rowclick="onRowClick"
    >
      <template v-slot:myTemplate="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <template v-slot:myDropDownCell="{ props }">
        <ddcell
          :data-item="props.dataItem"
          :field="props.field"
          @change="(e) => ddChange(e, props.dataItem)"
        />
      </template>
      <grid-toolbar style="display: flex; justify-content: flex-start; align-items: center;">
        <div >
          <v-btn
            v-show="
              user.claims.user_role == 'super_user' ||
              user.claims.user_role == 'customer_admin' ||
              user.claims.user_role == 'customer_edit'
            "
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            id="new_button"
            @click="insert"
          >
            Add new
          </v-btn>&nbsp;
          <v-btn id="export_excel" @click="exportExcel"> Export Data </v-btn>&nbsp;
          <v-btn id="export_excel" @click="showColumnModal">Select Fields</v-btn>
          <v-btn
            v-if="hasItemsInEdit"
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-danger"
            @click="cancelChanges"
          >
            Cancel current changes
          </v-btn>
        </div>
        <v-btn-toggle
          v-show="user.claims.user_role == 'super_user'"
          v-model="allToggle"
          style="margin-left: auto"
          id="btn_toolbar"
        >
          <v-btn :disabled="allDisabled" value="left" :ripple="high" :elevation="high ? 1 : 0" @click="viewAll()">
            <span class="hidden-sm-and-down">All</span>
          </v-btn>

          <v-btn :disabled="sunDisabled" value="right" :ripple="high" :elevation="high ? 1 : 0" @click="viewSunovision()">
            <span class="hidden-sm-and-down">Sunovision Managed</span>
          </v-btn>

          <v-btn :disabled="customDisabled" value="justify" :ripple="high" :elevation="high ? 1 : 0" @click="viewCustomer()">
            <span class="hidden-sm-and-down">Customer Managed</span>
          </v-btn>
        </v-btn-toggle>
      </grid-toolbar>
      <grid-norecords>There is no data available custom </grid-norecords>
    </Grid>
    <new-case-modal
      v-bind:show_switch="visibleDialog"
      v-bind:edit_item="edit_item"
      @save="save"
      @saveAndClose="saveAndClose"
      @cancel="handleCancel"
      @close="handleClose"
      @remove="remove"
    ></new-case-modal>
    <!-- column dialog -->
    <v-dialog v-model="columnDialog" width="300">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Columns
          <v-spacer></v-spacer>
          <v-btn icon @click="columnDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <!-- <p>{{ selected_fields }}</p> -->
          <div v-for="f in case_fields">
              <v-checkbox v-model="selected_fields" :label="`${f.title}`" :value="f"></v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="saveColumns"> Save Columns </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import CommandCell from "./CommandCell";
import DropDownCell from "./DropDownCell.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import { saveExcel } from "@progress/kendo-vue-excel-export";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";

import NewCaseModal from "./NewCaseModal.vue";

Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    ddcell: DropDownCell,
    custom: CommandCell,
    NewCaseModal,
  },
  data: function () {
    return {
      value: [],
      allDisabled: true,
      sunDisabled: false,
      customDisabled: false,
      siteId: 0, //TODO: reset this to 0 after testing
      contact: null,
      dateAddedMenu: null,
      dateAddedVal: null,
      // dateClosedMenu: null,
      // dateClosedVal: null,
      site_edit_value: "",
      valid: true,
      edit_item: null,
      equipmentType: "inverter",
      equipment: null,
      equipmentName: null,
      inverters: null,
      id_edit: null,
      subject_edit: null,
      case_category_edit: null,
      date_added_edit: null,
      // date_closed_edit: null,
      kw_affected_edit: null,
      downtime_days_edit: null,
      downtime_pct_edit: null,
      lost_production_edit: null,
      site_edit: null,
      account_edit: null,
      case_status_edit: null,
      vendor_case_num_edit: null,
      monitoring_plat_edit: null,
      allToggle: 2,
      allCases: null,
      sunovisionCases: null,
      customerCases: null,
      address_line_1_edit: null,
      city_edit: null,
      visibleDialog: false,
      dialogQtrData: false,
      pause_dialog: false,
      filter: null,
      pageable: { pageSizes: [10, 20, 50, 100] },
      gridData: [],
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      updatedData: [],
      selectedID: 1,
      selectedField: "selected",
      editID: null,
      sort: [],
      case_fields: [
        {
          field: "kw_affected",
          filterable: false,
          title: "Kw Affected",
          width: "100px",
        },
        {
          field: "lost_production",
          filterable: false,
          title: "Lost Production",
          width: "100px",
        },
        { field: "case_status.name", title: "Status", width: "150px" },
        { field: "case_category.name", title: "Category", width: "150px" },
        { field: "site.customerAccountName", title: "Account", width: "150px" },
        {
          field: "contact.full_name",
          title: "Onsite Service Team",
          width: "150px",
        },
        { field: "vendor_case_num", title: "Vendor Case #", width: "150px" },
        {
          field: "case_priority.name",
          title: "Priority",
          width: "150px",
        },
        { field: "address_line_1", title: "Address", width: "200px" },
        { field: "city", title: "City", width: "150px" },
        {
          field: "notes",
          title: "Notes",
          width: "200px",
          cell: this.notesCellFunction,
        },
      ],
      high: true,
      columnDialog: false,
      selected_fields: [],
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },
    dateAddedDisp() {
      return this.dateAddedVal;
    },
    result: {
      get: function () {
        return filterBy(orderBy(this.gridData, this.sort), this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    columns: function () {
      let baseColumns = [
        { field: "site.name", title: "Site", width: "150px" },
        { field: "id", hidden: true, editable: false, title: "ID" },
        { field: "subject", title: "Subject", width: "200px" },
        {
          field: "date_added",
          editable: true,
          format: "{0:d}",
          title: "Date Added",
          width: "150px",
        },
      ];
      // add in additional columns
      if (this.$store.state.alerts.caseColumns.length > 0) {
        this.$store.state.alerts.caseColumns.forEach((col) => {
          // baseColumns.push(element);
          if (col.field === 'notes') {
            baseColumns.push({ ...col, cell: this.notesCellFunction });
          } else {
            baseColumns.push(col);
          }
        });
      }
      return baseColumns;
    },
  },
  /*beforeRouteUpdate (to, from) {
    //feature/580
    if(this.$route.params.id){
      this.loadCaseFromSearch(this.gridData);
    }
  },*/
  mounted() {
    document.title = "Open Cases";
  },
  created: function () {
    this.getData();
    this.selected_fields = this.$store.state.alerts.caseColumns.map(col => {
      if (col.field === 'notes') {
        return { ...col, cell: this.notesCellFunction };
      }
      return col;
    });
  },
  methods: {
    // close on child new case modal, refresh grid
    handleCancel() {
      console.log("handleCancel");
      this.visibleDialog = false;
      // Handle the cancellation here, e.g., close the modal or perform other actions
      // this.getData();
      this.$router.push("/cases/open-cases");
    },
    handleClose() {
      console.log("handleClose");
      this.visibleDialog = false;
      this.$router.push("/cases/open-cases");

    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    exportExcel() {
      saveExcel({
        data: this.dataToExport(),
        fileName: "open_cases.xlsx",
        columns: this.columns,
      });
    },
    dataToExport() {
      const data = this.filter ? this.result : this.gridData;
      // Format the data to include notes as a concatenated string
      return data.map((item) => ({
        ...item,
        notes: item.notes ? item.notes.map((note) => note.note).join(", ") : "",
      }));
    },
    formatNotesTooltip(notes) {
      if (!notes || notes.length === 0) return '';
      return notes.map(note => note.note).join('\n');
    },
    notesCellFunction(h, tdElement, props, listeners) {
      const notesText = this.formatNotesTooltip(props.dataItem.notes);
      // const displayText = notesText ? 'View Notes' : 'No Notes';
      const displayText = notesText ? notesText.slice(0, 50) + (notesText.length > 50 ? '...' : '') : 'No Notes';
      return h('td', {
        attrs: {
          title: notesText
        },
        class: 'notes-cell'
      }, displayText);
    },
    onClickOutside() {
      this.toggleDialog();
    },
    viewAll() {
      this.allDisabled = true;
      this.sunDisabled = false;
      this.customDisabled = false;
      this.gridData = null;
      this.gridData = this.allCases;
    },
    viewSunovision() {
      this.allDisabled = false;
      this.sunDisabled = true;
      this.customDisabled = false;
      this.gridData = null;
      this.gridData = this.sunovisionCases;
    },
    viewCustomer() {
      this.allDisabled = false;
      this.sunDisabled = false;
      this.customDisabled = true;
      this.gridData = null;
      this.gridData = this.customerCases;
    },
    getData: function () {
      // eslint-disable-next-line no-undef
      NProgress.start();
      Service.getCasesNotDone()
        .then((response) => {
          const r = response.data;
          this.allCases = r;
          this.sunovisionCases = r.filter(
            (obj) => obj.site.managingCases === true
          );
          this.customerCases = r.filter(
            (obj) => obj.site.managingCases === false
          );
          this.gridData = this.allCases;
          // this.gridData = response.data.filter(obj => obj.site.managingCases === true);
          if (this.$route.params.id) {
            this.loadCaseFromSearch(this.gridData);
          }
          // eslint-disable-next-line no-undef
          NProgress.done();
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
          // eslint-disable-next-line no-undef
          NProgress.done();
        });
    },
    getLostProduction(case_id) {
      Service.getCaseLostProduction(case_id)
        .then((response) => {
          this.lost_production_edit = response.data.lost_production;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    loadCaseFromSearch(array) {
      let caseIdFromSearch = this.$route.params.id;
      var lookup = {};
      for (var i = 0, len = array.length; i < len; i++) {
        lookup[array[i].id] = array[i];
      }

      this.edit_item = lookup[caseIdFromSearch];
      this.id_edit = lookup[caseIdFromSearch].id;
      this.subject_edit = lookup[caseIdFromSearch].subject;
      this.siteId = lookup[caseIdFromSearch].site.id;
      this.caseStatus = lookup[caseIdFromSearch].case_status;
      this.caseCategory = lookup[caseIdFromSearch].case_category;
      this.casePriority = lookup[caseIdFromSearch].case_priority;
      this.date_added_edit = lookup[caseIdFromSearch].date_added;
      this.dateAddedVal = this.$moment(
        lookup[caseIdFromSearch].date_added
      ).format("YYYY-MM-DD");
      this.dateClosedVal = this.$moment(
        lookup[caseIdFromSearch].date_closed
      ).format("YYYY-MM-DD");
      this.kw_affected_edit = lookup[caseIdFromSearch].kw_affected;
      this.downtime_pct_edit = lookup[caseIdFromSearch].downtime_pct;
      this.lost_production_edit = lookup[caseIdFromSearch].lost_production;
      this.contact = lookup[caseIdFromSearch].contact;
      this.vendor_case_num_edit = lookup[caseIdFromSearch].vendor_case_num;
      if (this.edit_item.site) {
        this.monitoring_plat_edit = this.edit_item.site.monitoringSystem;
        this.address_line_1_edit = this.edit_item.site.address.street;
        this.city_edit = this.edit_item.site.address.city;
      }

      this.toggleDialog();

      // if we loaded the case from the search path, we need to clear it.
      this.$router.replace({
        path: this.$route.path,
        query: this.$route.query,
      });
      // this.$router.push("/cases/open-cases");
    },
    onRowClick(event) {
      // this.selectedID = event.dataItem.id;
      // set edit item
      // this.resetFormValues();
      this.edit_item = event.dataItem;

      this.toggleDialog();
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    dataStateChange: function (event) {
      console.log(`dataStateChange...${event}`);
    },

    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    insert() {
      // we want edit_item to be null on new case creation
      this.edit_item = null;
      this.toggleDialog();
    },
    edit: function (e) {
      debugger;
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    saveColumns() {
      // this.$store
      this.$store.dispatch("setCaseColumns", this.selected_fields);
    },
    showColumnModal() {
      this.columnDialog = true;
    },
    saveAndClose: function (e, equip, case_owners) {
      var today = new Date();
      // create if id is null
      if (e.dataItem.id == undefined) {
        Service.createCase(e.dataItem, equip, case_owners)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case created`, {
              position: "top-right",
              duration: 4000,
            });
            e.dataItem.id = response.data.id;
            // set case id in edit
            this.id_edit = response.data.id;
            this.edit_item = e.dataItem;
            this.edit_item.site = response.data.site;
            // this.gridData.splice(index, 1, updated);
            // reload data grid
            this.getData();
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        if (e.dataItem.case_status.name == "Done") {
          // set closed date
          e.dataItem.date_closed = this.$moment(today).format("YYYY-MM-DD");
        } else {
          // remove downtime_days unless we are closing it out
          delete e.dataItem.downtime_days;
          delete e.dataItem.downtime_numbers;
        }
        Service.updateCase(e.dataItem.id, e.dataItem)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case updated`, {
              position: "top-right",
              duration: 4000,
            });
            const c = response.data;
            e.dataItem.id = response.data.id;
            this.id_edit = c.id;
            this.edit_item = e.dataItem;
            this.edit_item.site = response.data.site;
            // this.gridData.splice(index, 1, updated);
            this.getData();
            if (c.case_status.name == "Done") {
              //this.toggleDialog();
            }
          })
          .catch((error) => {
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    save: function (e, equip, case_owners) {
      var today = new Date();
      // create if id is null
      if (e.dataItem.id == undefined) {
        Service.createCase(e.dataItem, equip, case_owners)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case created`, {
              position: "top-right",
              duration: 4000,
            });
            e.dataItem.id = response.data.id;
            // set case id in edit
            this.id_edit = response.data.id;
            this.edit_item = e.dataItem;
            this.edit_item.site = response.data.site;
            // this.gridData.splice(index, 1, updated);
            // reload data grid
            this.getData();
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        if (e.dataItem.case_status.name == "Done") {
          // set closed date
          e.dataItem.date_closed = this.$moment(today).format("YYYY-MM-DD");
        } else {
          // remove downtime_days unless we are closing it out
          delete e.dataItem.downtime_days;
          delete e.dataItem.downtime_numbers;
        }
        Service.updateCase(e.dataItem.id, e.dataItem)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case updated`, {
              position: "top-right",
              duration: 4000,
            });
            const c = response.data;
            e.dataItem.id = response.data.id;
            this.id_edit = c.id;
            this.edit_item = e.dataItem;
            this.edit_item.site = response.data.site;
            // this.gridData.splice(index, 1, updated);
            this.getData();
            if (c.case_status.name == "Done") {
              //this.toggleDialog();
            }
          })
          .catch((error) => {
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    update(data, item, remove) {
      let updated;
      let caseID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (caseID && c.id === caseID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );

        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteCase(e.dataItem.id)
        .then((response) => {
          this.toggleDialog();
          Vue.$toast.warning(`Case removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style>
#openX_btn {
  float: right;
  width: 48px;
  height: 48px;
}
.k-toolbar #new_button {
  float: left;
}
.k-toolbar #btn_toolbar {
  float: right;
}
.notes-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>