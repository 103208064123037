<template>
  <div>
    <h3>{{ this.siteName }}</h3>
    <Grid
      ref="grid"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
      :selected-field="selectedField"
      @rowclick="onRowClick"
    >
      <template v-slot:myTemplate="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <!-- Pause inverters feature -->
      <template v-slot:isPaused="{ props }">
        <!-- {{ props }} -->
        <v-checkbox 
          v-model="props.dataItem.is_paused"
          class="ml-9" 
          @change="(e) => pauseComponentPopup(e, props.dataItem.id)"
        ></v-checkbox>
      </template>
      <grid-toolbar>
        <v-btn 
          :disabled="user.claims.user_role == 'customer_view'" 
          title="Add new" 
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default" 
          @click="insert"
        >
          Add new
        </v-btn>
        <v-btn
          v-if="hasItemsInEdit"
          title="Cancel current changes"
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-danger"
          @click="cancelChanges"
        >
          Cancel current changes
        </v-btn>
        <v-btn 
          :disabled="user.claims.user_role == 'customer_view'" 
          title="Pause/Unpause all" 
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default" 
          @click="pauseUnpauseAllPopup"
        >
          Pause/Unpause all
        </v-btn>
        <v-btn
          :disabled="user.claims.user_role == 'customer_view'"
          title="Inverter Factors"
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          @click="showInverterFactorDialog"
        >
          Show Monthly Inverter Adjustment Factors
        </v-btn>
        <div class="text-center">
          <v-dialog
            v-model="pause_unpause_dialog"
            width="500"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Pausing/Unpausing All Inverters
              </v-card-title>

              <v-card-text class="text-h8 mt-4">
                Are you sure? **Triple check** if there is a case open related to these before changing. These will be paused until manually undone.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="
                    font-weight-bold
                    text-xs
                    btn-danger
                    bg-gradient-danger
                  "
                  @click="pause_unpause_dialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="
                    font-weight-bold
                    text-xs
                    btn-success
                    bg-gradient-success
                  "
                  @click="pauseUnpauseAll"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div class="text-center">
          <v-dialog
            v-model="component_dialog"
            persistent
            width="500"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Pausing/Unpausing Inverter
              </v-card-title>

              <v-card-text class="text-h8 mt-4">
                Are you sure? **Triple check** if there is a case open related to this before changing. This will be paused until it's manually undone.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="
                    font-weight-bold
                    text-xs
                    btn-danger
                    bg-gradient-danger
                  "
                  @click="cancelPauseInverter"
                >
                  Cancel
                </v-btn>
                <v-btn
                  class="
                    font-weight-bold
                    text-xs
                    btn-success
                    bg-gradient-success
                  "
                  @click="pauseInverter"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div v-if="siteId" class="text-center">
          <inverter-factor-dialog
            v-model="showInverterDialog"
            :inverter-data="gridData"
            :site-id="siteId"
          />
        </div>
      </grid-toolbar>
      <grid-norecords> There is no data available </grid-norecords>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import CommandCell from "./CommandCell";
import InverterAccountDropDownCell from "./InverterAccountDropDownCell.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
import InverterFactorDialog from "./InverterFactorDialog.vue";
Vue.use(VueToast);
export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    ddcell: InverterAccountDropDownCell,
    custom: CommandCell,
    InverterFactorDialog,
  },
  data: function() {
    return {
      pause_unpause_dialog: false,
      e: null,
      inverterId: null,
      component_dialog: false,
      filter: null,
      //for now
      sort: null,
      pageable: { pageSizes: [10, 20, 50, 100] },
      gridData: [],
      siteName: null,
      siteId: null,
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      updatedData: [],
      selectedID: 1,
      selectedField: "selected",
      editID: null,
      sort: [{ field: "name", dir: "asc" }],
      columns: [
        { field: "id", hidden: true, editable: false, title: "ID" },
        { field: "name", width: "200" },
        {
          field: "dc_size_kw",
          title: "DC size kw",
          width: "150",
        },
        {
          field: "ac_size_kw",
          title: "AC size kw",
          width: "175",
        },
        {
          field: "azimuth",
          title: "Azimuth",
          width: "175",
        },
        { field: "tilt", title: "Tilt", width: "150" },
        {
          field: "inverter_monitoring_id",
          title: "Inverter Monitoring ID",
          width: "150",
        },
        { cell: "isPaused", title: "Paused", filterable: false, width: "100px"},
        { cell: "myTemplate", filterable: false, width: "200px" },
      ],
      showInverterDialog: false,
    };
  },
  updated() {
    var title = this.siteName + ' Inverters'
    document.title = title
  },
  computed: {
    user: function() {
      return this.$store.getters.currentUser;
    },
    result: {
      get: function() {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
  },
  created: function() {
    this.getData();
    this.getSiteName();
  },
  methods: {
    showInverterFactorDialog() {
      console.log(`showInverterDialog = true`);
      this.showInverterDialog = true;
    },
    getSiteName: function() {
      NProgress.start();
      let site_id = parseInt(this.$route.params.id)
      Service.getSite(site_id)
        .then((response) => {
          NProgress.done();
          this.siteName = response.data.name;
          this.siteId = response.data.id;
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        })
    },
    pauseComponentPopup(e, inverter_id) {
      this.e = e;
      this.inverterId = inverter_id;
      this.component_dialog = true;
    },
    cancelPauseInverter() {
      this.e = null;
      this.inverterId = null;
      this.component_dialog = false;
      this.getData();
    },
    pauseInverter() {
      let params = {
        isPaused: this.e
      }
      let siteId = parseInt(this.$route.params.id)
      this.component_dialog = false;
      Service.updateInverterPause(siteId, this.inverterId, params)
        .then((response) => {
          console.log(response)
          this.e = null;
          this.inverterId = null;
          this.getData();
        })
        .catch((error) => {
          Vue.$toast.error(`Error pausing inverter`, { position: "top-right" });
          console.log(error);
        });
    },
    pauseUnpauseAllPopup() {
      this.pause_unpause_dialog = true;
    },
    pauseUnpauseAll() {
      const allPaused = this.gridData.every(obj => obj.is_paused === true);
      let siteId = parseInt(this.$route.params.id)
      if (allPaused) {
        this.pause_unpause_dialog = false;
        this.gridData.forEach(obj => {
          let params = {
            isPaused: false
          }
          let siteId = parseInt(this.$route.params.id)
          this.component_dialog = false;
          Service.updateInverterPause(siteId, obj.id, params)
            .then((response) => {
              console.log(response)
              this.getData();
            })
            .catch((error) => {
              Vue.$toast.error(`Error pausing inverter`, { position: "top-right" });
              console.log(error);
            });
        });
      } else {
        this.pause_unpause_dialog = false;
        this.gridData.forEach(obj => {
          let params = {
            isPaused: true
          }
          let siteId = parseInt(this.$route.params.id)
          this.component_dialog = false;
          Service.updateInverterPause(siteId, obj.id, params)
            .then((response) => {
              console.log(response)
              this.getData();
            })
            .catch((error) => {
              Vue.$toast.error(`Error pausing inverter`, { position: "top-right" });
              console.log(error);
            });
        });
      }
    },
    filterChange: function(ev) {
      this.filter = ev.filter;
    },
    getData: function() {
      NProgress.start();
      let site_id = parseInt(this.$route.params.id)
      Service.getInvertersGridAll(site_id)
        .then((response) => {
          console.log(response)
          const inverters = response.data.map((s) => {
            s.exp_date = new Date(s.exp_date);
            return s;
          });
          this.gridData = inverters;
          NProgress.done();
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.id;
      console.log(`selected ${this.selectedID}`);
    },
    sortChangeHandler: function(e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000);
    },
    dataStateChange: function(event) {
      console.log("dataStateChange...");
    },

    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    insert() {
      const dataItem = { inEdit: true };
      const newInverters = this.gridData.slice();
      newInverters.unshift(dataItem);
      this.update(newInverters, dataItem);
      this.gridData = newInverters;
    },
    edit: function(e) {
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    save: function(e) {
      let customerID = e.dataItem.id;
      let index = this.gridData.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(e.dataItem) ||
          (customerID && c.id === customerID)
      );
      let item = this.gridData[index];
      let updated = Object.assign(this.update(this.gridData.slice(), item), {
        inEdit: undefined,
      });
      var siteID = this.$route.params.id;
      if (updated.id == undefined) {
        NProgress.start();
        Service.createInverterNew(siteID, updated)
          .then((response) => {
            Vue.$toast.success(`Inverter created`, {
              position: "top-right",
              duration: 4000,
            });
            updated.id = response.data.id;
            this.gridData.splice(index, 1, updated);
            NProgress.done();
          })
          .catch((error) => {
            NProgress.done();
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        NProgress.start();
        Service.updateInverterGrid(siteID, updated.id, updated)
          .then((response) => {
            Vue.$toast.success(`Inverter updated`, {
              position: "top-right",
              duration: 4000,
            });
            this.gridData.splice(index, 1, updated);
            NProgress.done();
          })
          .catch((error) => {
            NProgress.done();
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    update(data, item, remove) {
      let updated;
      let customerID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (customerID && c.id === customerID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }
      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );
        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      var siteID = this.$route.params.id;
      Service.deleteInverter(siteID, e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Inverter removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
  },
};
</script>

<style></style>
