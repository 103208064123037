import axios from "axios";
import Url from "@/services/Url.js";

// setup headers
const headers = () => {
  const jwtToken = localStorage.getItem("token")
  let headers = {}

  return (jwtToken) ? Object.assign(headers, {"Authorization": `Bearer ${jwtToken}`}) : headers
}

const apiJSONClient = () => {
  return axios.create({
    baseURL: Url.https(),
    withCredentials: false,
    headers: Object.assign(headers(), {
      Accept: "application/json",
      "Content-Type": "application/json"
    })
  });
}

const apiUploaderClient = () => {
  return axios.create({
    baseURL: Url.https(),
    withCredentials: false,
    headers: Object.assign(headers(), {
      "Content-Type": "multipart/form-data"
    })
  });
}

const apiCSVClient = () => {
  return axios.create({
    baseURL: Url.https(),
    withCredentials: false,
    headers: Object.assign(headers(), {
      "Content-Type": "text/csv"
    })
  });
}

const apiPDFClient = () => {
  return axios.create({
    baseURL: Url.https(),
    withCredentials: false,
    responseType: 'blob',
    headers: Object.assign(headers(), {
      Accept: "application/json",
      "Content-Type": "application/json"
    }),
  })
}

const apiZipClient = () => {
  return axios.create({
    baseURL: Url.https(),
    withCredentials: false,
    responseType: 'blob',
    headers: Object.assign(headers(), {
      Accept: "application/json",
      "Content-Type": "application/json"
    }),
  })
}

//TODO: this is where all API calls are configured to Sunovision API
export default {
  // auth
  login(user) {
    return apiJSONClient().post("/login", user);
  },
  register(user) {
    return apiJSONClient().post("/register", user);
  },
  confirmEmail(token) {
    return apiJSONClient().get(`/verifyemail/confirm?token=${token}`);
  },
  getLogsAll() {
    return apiJSONClient().get("/sitelogs/all");
  },
  getLogsBySiteId(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/sitelogs/all`);
  },
   // dashboard
  getSiteDashboardMetrics(date) {
    return apiJSONClient().get(`/dashboard/get_site_metrics?date=${date}`);
  },
  getSiteProjectionMetrics(date) {
    return apiJSONClient().get(`/dashboard/get_site_projections?date=${date}`);
  },
  getInverterDashboardMetrics(date) {
    return apiJSONClient().get(`/dashboard/get_inverter_metrics?date=${date}`);
  },
  getCasesandSites(id) {
    const promise1 = this.getCasesBySiteId(id);
    const promise2 = this.getSite(id);
    return Promise.all([promise1, promise2]);
  },
  getCasesandSitesSc(id, token) {
    const promise1 = this.getCasesBySiteIdSc(id, token);
    const promise2 = this.getSiteSc(id, token);
    return Promise.all([promise1, promise2]);
  },
  getDashboardMetrics(date) {
    const promise1 = this.getSiteDashboardMetrics(date);
    const promise2 = this.getInverterDashboardMetrics(date);
    const promise3 = this.getUserChecks();
    return Promise.all([promise1, promise2, promise3]);
  },
  getAppBarData() {
    const promise1 = this.getCasesNotDone();
    const promise2 = this.getSitesGrid();
    const promise3 = this.getAccounts();
    const promise4 = this.getContacts();
    return Promise.all([promise1, promise2, promise3, promise4]);
  },

  getCaseYearlyOpenStats(year) {
    return apiJSONClient().get(`/cases_get_case_yearly_open_stats?year=${year}`);
  },
  //Users
  getUser(id) {
    return apiJSONClient().get("/users/" + id);
  },
  getEmailCheck(email) {
    return apiJSONClient().get(`/users_email_check?email=${email}`);
  },
  isUserAccountAdmin(userId, accountId) {
    return apiJSONClient().get(`/is_user_account_admin?user_id=${userId}&account_id=${accountId}`);
  },
  getUsers() {
    return apiJSONClient().get("/users");
  },
  getUserTypes() {
    return apiJSONClient().get("/usertypes");
  },
  getUserSharesByAccount(accountId) {
    return apiJSONClient().get(`/users_by_account?account_id=${accountId}`);
  },
  updateUser(id, params) {
    const user = {user: params}
    return apiJSONClient().post(`/user/${id}/update`, user)
  },
  // cache
  resetCache() {
    return apiJSONClient().get(`/reset_cache`);
  },
  // sites
  getAllSites() {
    return apiJSONClient().get("/sites");
  },
  getSitesGrid() {
    return apiJSONClient().get("/sites/grid");
  },
  getInverterMetricsBySiteAndDate(siteId, date) {
    return apiJSONClient().get(`/sites/${siteId}/inverters/metrics_by_date?date=${date}`);
    // sites/1800/inverters/metrics_by_date?date=2020-12-19
  },
  getSites(metricsEndDate) {
    return apiJSONClient().get("/sites/?date=" + metricsEndDate);
  },
  getSitesFuture(metricsEndDate) {
    return apiJSONClient().get("/sites_future?date=" + metricsEndDate);
  },
  getSitesFutureAll(metricsEndDate) {
    return apiJSONClient().get("/sites_future_all?date=" + metricsEndDate);
  },
  // Customer
  getCustomers() {
    return apiJSONClient().get("/customers");
  },
  getCompanies() {
    return apiJSONClient().get("/customers");
  },
  updateCustomer(id, params) {
    const customer = {customer: params}
    return apiJSONClient().post(`/customers/${id}/update`, customer)
  },
  createCustomer(params) {
    const customer = {customer: params}
    return apiJSONClient().post(`/customers/create`, customer)
  },
  deleteCustomer(id) {
    return apiJSONClient().delete(`/customers/${id}`)
  },
  // Account
  getAccounts() {
    return apiJSONClient().get("/accounts");
  },
  getAccount(id) {
    return apiJSONClient().get(`/accounts/${id}`);
  },
  getAccountsByCompany(companyId) {
    return apiJSONClient().get(`/accountsbycompany/${companyId}`);
  },
  updateAccount(id, params) {
    const account = {account: params}
    return apiJSONClient().post(`/accounts/${id}/update`, account)
  },
  createAccount(params) {
    const account = {user_id: '', account: params}
    return apiJSONClient().post(`/accounts/create`, account)
  },
  deleteAccount(id) {
    return apiJSONClient().delete(`/accounts/${id}`)
  },
  shareAccountWithUser(userId, accountId) {
    return apiJSONClient().get(`/accounts/share_account_with_user?user_id=${userId}&account_id=${accountId}`);
  },
  shareAccountWithUserEmail(email, accountId) {

  },
  createMasterSite(master_site_id, sites, components) {
    const params = {
      master_site_id: master_site_id, 
      sites: sites,
      components: components
    }
    return apiJSONClient().post('/create_master_site', params);
  },
  sendEmail(contact, site_name, note, subject, vendor_num, case_id, note_id) {
    const params = {
      contact: contact, 
      site_name: site_name,
      note: note,
      subject: subject,
      vendor_num: vendor_num,
      case_id: case_id,
      note_id: note_id,
    };
    return apiJSONClient().post("/contact/send_mention", params);
  },
  sendCustomerQuestion(subject, content, file) {
    let formData = new FormData();
    formData.append("subject", subject);
    formData.append("content", content);
    formData.append("file", file);

    return apiJSONClient().post('/contact/send_customer_question', formData);
  },
  createFeedback(priority, date, user, f) {
    const params = {
      priority: priority,
      date: date,
      user: user,
      suggestion: f
    };
    const feedback = {feedback: params}
    return apiJSONClient().post(`/feedback/create_suggestion`, feedback);
  },
  deleteFeedback(id) {
    return apiJSONClient().delete(`/feedback/delete_feedback/${id}`);
  },
  getFeedback() {
    return apiJSONClient().get(`/feedback/get_suggestion`);
  },
  removeAccountUserShare(userId, accountId) {
    return apiJSONClient().get(`/accounts/remove_account_user_share?user_id=${userId}&account_id=${accountId}`);
  },
  // Contact
  getContactRelationBySite(siteId) {
    return apiJSONClient().get(`/contacts/${siteId}/by_site`);
  },
  createContactSiteRelation(siteId, params) {
    const obj  = {
      site_id: siteId,
      contact: params
    }
    return apiJSONClient().post(`/contacts/create_relationship_with_site`, obj);
  },
  excludeFromReport(contactId, params) {
    const obj = {
      contact: params
    }
    return apiJSONClient().post(`/contacts/${contactId}/update`, obj)
  },
  deleteContactRelationshipBySite(siteId, contactId) {
    return apiJSONClient().get(`/contact_relationship/${siteId}/delete_by_site?contact_id=${contactId}`)
  },
  getAllContactData() {
    const promise1 = this.getCustomers();
    const promise2 = this.getSitesGrid();
    const promise3 = this.getAccounts();
    const promise4 = this.getContacts();
    return Promise.all([promise1, promise2, promise3, promise4]);
  },
  getRelations(contactId) {
    return apiJSONClient().get(`/contact_relationship/by_contact?contact_id=${contactId}`)
  },
  createRelation(params) {
    const rel = {
      relationship: params
    }
    return apiJSONClient().post(`/contacts/create_relationship`, rel)
  },
  deleteRelation(params) {
    return apiJSONClient().post(`contact_relationship/delete_relationship`, params)
  },
  getContacts() {
    return apiJSONClient().get("/contacts");
  },
  getContact(id) {
    return apiJSONClient().get(`/contacts/${id}`);
  },
  updateContact(id, params) {
    const contact = {contact: params}
    return apiJSONClient().post(`/contacts/${id}/update`, contact)
  },
  createContact(params) {
    const contact = {contact: params}
    return apiJSONClient().post(`/contacts/create`, contact)
  },
  deleteContact(id) {
    return apiJSONClient().delete(`/contacts/${id}`)
  },
  deleteUser(id) {
    return apiJSONClient().delete(`/users/${id}`)
  },
  // Reporting Groups
  getReportingGroups() {
    return apiJSONClient().get("/reportinggroups");
  },
  getReportingGroup(id) {
    return apiJSONClient().get(`/reportinggroups/${id}`);
  },
  updateReportingGroup(id, params) {
    const rg = {
      id: id,
      reporting_group: params
    }
    return apiJSONClient().post(`/reportinggroups/${id}/update`, rg)
  },
  createReportingGroup(params) {
    const rg = {reporting_group: params}
    return apiJSONClient().post(`/reportinggroups/create`, rg)
  },
  deleteReportingGroup(id) {
    return apiJSONClient().delete(`/reportinggroups/${id}`)
  },
  // Checks
  createCheck(params) {
    const chk = {check: params}
    return apiJSONClient().post(`/checks/create`, chk)
  },
  // checks for the past month for the current user
  getUserChecks() {
    return apiJSONClient().get("/checks/pastmonth");
  },
  // Case Notes
  getCasesNotDone() {
    return apiJSONClient().get("/cases_not_done");
  },
  getCasesByStatus(status) {
    return apiJSONClient().get(`/cases_by_status/${status}`);
  },
  getCaseNotes() {
    return apiJSONClient().get("/casenotes");
  },
  getCaseNotesByCase(case_id) {
    return apiJSONClient().get(`/casenotes_by_case/${case_id}`);
  },
  getCaseLostProduction(case_id) {
    return apiJSONClient().get(`/cases/${case_id}/lost_production`);
  },
  getCaseDowntimePct(case_id) {
    return apiJSONClient().get(`/cases/${case_id}/downtime_pct`);
  },
  getCaseDetermineQtr(case_id, start_date, end_date) {
    return apiJSONClient().get(`/cases/${case_id}/determine_qtr?start_date=${start_date}&end_date=${end_date}`);
  },  
  createCaseNote(params) {
    const c = {case_note: params}
    return apiJSONClient().post(`/casenotes/create`, c)
  },
  updateCaseNote(id, params) {
    const c = {
      id: id,
      case_note: params
    }
    return apiJSONClient().post(`/casenotes/${id}/update`, c)
  },
  deleteCaseNote(id) {
    return apiJSONClient().delete(`/casenotes/${id}`)
  },
  // CaseStatus
  getCaseStatus() {
    return apiJSONClient().get("/casestatus");
  },
  createCaseStatus(params) {
    const c = {case_status: params}
    return apiJSONClient().post(`/casestatus/create`, c)
  },
  getCaseCategories() {
    return apiJSONClient().get("/casecategories");
  },
  createCaseCategory(params) {
    const c = {case_category: params}
    return apiJSONClient().post(`/casecategories/create`, c)
  },
  getCasePriorities() {
    return apiJSONClient().get("/casepriorities");
  },
  createCasePriority(params) {
    const c = {case_priority: params}
    return apiJSONClient().post(`/casepriorities/create`, c)
  },
  // Cases
  getCases() {
    return apiJSONClient().get("/cases");
  },
  
  getCase(id) {
    return apiJSONClient().get(`/cases/${id}`);
  },
  updateCase(id, params) {
    // convert pct to float
    // params.downtime_pct = parseFloat(params.downtime_pct)
    // we never want to save the downtime_pct here...
    delete params.downtime_pct;

    const c = {
      id: id,
      case: params,
    };

    return apiJSONClient().post(`/cases/${id}/update`, c);
  },
  createCase(params, equipment, case_owners) {
    params.downtime_pct = parseFloat(params.downtime_pct);
    const c = {
      case: params,
      equipment: equipment,
      case_owners: case_owners,
    };
    return apiJSONClient().post(`/cases/create`, c);
  },
  deleteCase(id) {
    return apiJSONClient().delete(`/cases/${id}`);
  },
  getCaseOwners(caseId) {
    return apiJSONClient().get(`/case_owners`, {
      params: { case_id: caseId },
    });
  },
  // Case Owner
  addCaseOwner(caseOwnerData) {
    return apiJSONClient().post(`/case_owners`, { case_owner: caseOwnerData });
  },
  // removeCaseOwner(contactId, caseId) {
  //   // Assuming you have a REST API that removes a case owner based on the contactId and caseId
  //   return apiJSONClient().delete(`/case_owners/${contactId}`, {
  //     data: { case_id: caseId }, // Send the case ID in the request body if required by your API
  //   });
  // },
  removeCaseOwner(contactId, caseId) {
    // Include both contactId and caseId in the URL as query parameters
    return apiJSONClient().delete(`/case_owners/${contactId}`, {
      params: { case_id: caseId }, // Add case_id as a query parameter
    });
  },
  backfillEstimates(siteId) {
    // const bp = {billing_plan: params}
    return apiJSONClient().post(`sites/${siteId}/backfill_estimates`);
    // return fetch(`/api/sites/${siteId}/backfill_estimates`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
  },
  // Equipment
  addEquipment(id1, id2, type) {
    return apiJSONClient().get(`/cases/add_equipment?equipment_id=${id1}&case_id=${id2}&equipment_type=${type}`)
  },
  removeEquipment(id1, id2) {
    return apiJSONClient().get(`/cases/remove_equipment?equipment_id=${id1}&case_id=${id2}`)
  },
  // Wizard
  getSiteApiCheck(site_id) {
    return apiJSONClient().get(`/wizard/get_site_api_check?site_id=${site_id}`);
  },
  getInverterApiCheck(site_id) {
    return apiJSONClient().get(`/wizard/get_inverter_api_check?site_id=${site_id}`);
  },
  getMissingThirtyForSite(site_id) {
    return apiJSONClient().get(`/wizard/missing_thirty_site?site_id=${site_id}`);
  },
  getMissingThirtyForInverter(site_id) {
    return apiJSONClient().get(`/wizard/missing_thirty_inverter?site_id=${site_id}`);
  },
  // Billing Plans
  getBillingPlans() {
    return apiJSONClient().get("/billingplans");
  },
  createBillingPlan(params) {
    const bp = {billing_plan: params}
    return apiJSONClient().post(`/billingplans/create`, bp)
  },
  updateBillingPlan(id, params) {
    const bp = {
      id: id,
      billing_plan: params
    }
    return apiJSONClient().post(`/billingplans/${id}/update`, bp)
  },
  deleteBillingPlan(id) {
    return apiJSONClient().delete(`/billingplans/${id}`)
  },
  // Alerts
  getAlerts() {
    return apiJSONClient().get(`/alerts`);
  },
  getUnreadAlertsByUser() {
    return apiJSONClient().get(`/alerts/get_unread_alerts_by_user`);
  },
  markAlertAsRead(id) {
    return apiJSONClient().post(`/alerts/mark_alert_as_read?alert_id=${id}`)
  },
  removeAlert(key) {
    return apiJSONClient().get(`/alerts/${key}/remove`)
  },
  // Estimates
  getEstimates(date) {
    return apiJSONClient().get(`/estimates/missing?date=${date}`);
  },
  // Fleet URl
  genFleetViewUrl(site_group_id) {
    return apiJSONClient().get(`/rpt/generate_fleet_view_url?site_group_id=${site_group_id}`);
  },
  // Site
  getSite(id) {
    return apiJSONClient().get("/sites/" + id);
  },
  getSiteSc(id, token) {
    return apiJSONClient().get(`/singlecustomer/sites/${id}/token/${token}`);
  },
  getSiteWithInvDetail(id) {
    return apiJSONClient().get(`/sites/${id}/with_inv_detail`);
  },
  getDailyHealthSiteSum(date) {
    return apiJSONClient().get(`/get_daily_health_site_sum?date=${date}`);
  },
  getDailyPerformanceSum(date) {
    return apiJSONClient().get(`/get_daily_performance_sum?date=${date}`);
  },
  get30DayStats(id, date) {
    return apiJSONClient().get(`/sites/${id}/get_thirty_day_summary?date=${date}`);
  },
  getSiteMonthlyStats(id, month, year) {
    return apiJSONClient().get(`/sites/${id}/get_monthly_stats?month=${month}&year=${year}`);
  },
  updateSiteMonthlyKwh(id, month, year, kwh) {
    return apiJSONClient().post(`/sites/${id}/update_monthly_kwh`, {month, year, kwh});
  },
  getSelectedComponents() {
    return apiJSONClient().get("/selected_components/");
  },
  getSiteYearlyComboStats(id, year) {
    const promise1 = this.getSiteYearlyStats(id, year);
    const promise2 = this.getSiteCompEnergyMonth(id)
    return Promise.all([promise1, promise2]);
  },
  getSiteYearlyComboStatsSc(id, year, token) {
    const promise1 = this.getSiteYearlyStatsSc(id, year, token);
    const promise2 = this.getSiteCompEnergyMonthSc(id, token)
    return Promise.all([promise1, promise2]);
  },
  getSiteYearlyStats(id, year) {
    return apiJSONClient().get(`/sites/${id}/get_yearly_stats?year=${year}`);
  },
  getSiteYearlyStatsSc(id, year, token) {
    return apiJSONClient().get(`/singlecustomer/sites/${id}/token/${token}/get_yearly_stats?year=${year}`);
  },
  getSiteMetricYears(id) {
    return apiJSONClient().get(`/sites/${id}/get_metric_years`);
  },
  reloadMissingMonthlyData(site_id) {
    return apiJSONClient().get(`/sites/${site_id}/load_missing_month_sum`);
  },
  reloadMissingMonthlyDataByProvider(provider_name) {
    return apiJSONClient().get(`/sites/${provider_name}/load_missing_month_by_provider`);
  },
  getSiteCompEnergyMonth(id) {
    return apiJSONClient().get(`/sites/${id}/get_comp_energy_month`);
  },
  getSiteCompEnergyMonthSc(id, token) {
    return apiJSONClient().get(`/singlecustomer/sites/${id}/token/${token}/get_comp_energy_month`);
  },
  getSiteCompEnergyYear(id) {
    return apiJSONClient().get(`/sites/${id}/get_comp_energy_year`);
  },
  getSiteCompEnergyYearSc(id, token) {
    return apiJSONClient().get(`/singlecustomer/sites/${id}/token/${token}/get_comp_energy_year`);
  },
  createSite(params) {
    return apiJSONClient().post('/sites/create', params)
  },
  createSiteNew(params) {
    const site = {site: params}
    return apiJSONClient().post('/sites/create', site)
  },
  updateSite(id, params) {
    return apiJSONClient().post(`/sites/${id}/update`, params)
  },
  updateSitePauseOnly(id, params) {
    return apiJSONClient().post(`/sites/${id}/update_pause_only`, params)
  },
  updateSiteGrid(id, params) {
    delete params.solar_module_wattage;
    let annual_visit = params.annual_visit
    params.annual_visit = annual_visit == "Y"
    const site = {
      "id": id,
      "site": params
    }
    return apiJSONClient().post(`/sites/${id}/update_grid`, site)
  },
  getMasterSites() {
    return apiJSONClient().get(`/master_sites`);
  },
  getChildSites(master_site_id) {
    return apiJSONClient().get(`/child_sites?master_site_id=${master_site_id}`);
  },
  getAllChildSites() {
    return apiJSONClient().get(`/all_child_sites`);
  },
  deleteChildSite(master_site_id, child_site_id) {
    return apiJSONClient().get(`/delete_child_site?master_site_id=${master_site_id}&child_site_id=${child_site_id}`);
  },
  deleteMasterSite(master_site_id) {
    return apiJSONClient().get(`/delete_master_site?master_site_id=${master_site_id}`);
  },
  selectChildComponent(master_site_id, component_id, selected) {
    return apiJSONClient().get(`/select_child_component?master_site_id=${master_site_id}&component_id=${component_id}&selected=${selected}`);
  },
  getChildSiteComponents(site_id) {
    return apiJSONClient().get(`/child_site_components?site_id=${site_id}`);
  },
  getReportList() {
    return apiJSONClient().get("/rpt/get_report_list");
  },
  getRptSiteGroups() {
    return apiJSONClient().get("/rpt/get_rpt_site_group");
  },
  getAllRptSiteGroups() {
    return apiJSONClient().get("/rpt/get_all_rpt_site_group");
  },
  getAllRptSiteGroupsFV(token) {
    return apiJSONClient().get(`/fleet/get_all_rpt_site_group_fv/?token=${token}`);
  },
  getAllRptSiteGroupsById(id) {
    return apiJSONClient().get(`/rpt/get_all_rpt_site_group_by_id/${id}`);
  },
  getDynRptSiteGroups() {
    return apiJSONClient().get("/rpt/get_dyn_rpt_site_group");
  },
  getDynSiteList(site_group_id) {
    return apiJSONClient().get(`/rpt/dynsitelist?id=${site_group_id}`);
  },
  createDynSiteGroup(params) {
    return apiJSONClient().post(`/rpt/create_dyn_site_group`, params)
  },
  updateDynSiteGroup(params) {
    return apiJSONClient().post(`/rpt/update_dyn_site_group`, params)
  },
  createDynSiteGroupV2(params) {
    return apiJSONClient().post(`/rpt/create_dyn_site_group_v2`, params)
  },
  createRptDynSiteFilter(params) {
    return apiJSONClient().post(`/rpt/create_rpt_dynamic_site_filter`, params)
  },
  deleteRptDynSiteFilter(params) {
    return apiJSONClient().post(`/rpt/delete_rpt_dynamic_site_filter`, params)
  },
  dynSiteGroupQuery(conditions) {
    return apiJSONClient().post(`/rpt/dyn_site_group_query`, conditions)
  },
  deleteRptSiteGroup(id) {
    return apiJSONClient().delete(`/rpt/rptsitegroups/${id}`);
  },
  deleteRptFieldList(id) {
    return apiJSONClient().delete(`/rpt/rptfieldlist/${id}`);
  },
  createReport(params) {
    return apiJSONClient().post(`/rpt/create_rpt`, params)
  },
  updateReport(params) {
    return apiJSONClient().post(`/rpt/update_rpt`, params)
  },
  deleteReport(params) {
    return apiJSONClient().post(`/rpt/delete_rpt`, params)
  },
  createRptSiteGroup(params) {
    return apiJSONClient().post(`/rpt/rpt_site_group_create`, params);
  },
  updateRptSiteGroup(params) {
    return apiJSONClient().post(`/rpt/rpt_site_group_update`, params);
  },
  createRptFieldList(params) {
    
    return apiJSONClient().post(`/rpt/create_rpt_field_list`, params)
  },
  updateRptFieldList(params) {
    
    return apiJSONClient().post(`/rpt/update_rpt_field_list`, params)
  },
  getRptInternalReports() {
    return apiJSONClient().get("/rpt/get_rpt_internal_reports");
  },
  getRptFieldList() {
    return apiJSONClient().get("/rpt/get_rpt_field_list");
  },
  deleteSite(id) {
    return apiJSONClient().delete(`sites/${id}`);
  },
  getSiteData(id, params) {
    return apiJSONClient().post(`/sites/${id}/get_data`, params);
  },
  getAllSiteData(params) {
    return apiJSONClient().post(`/sites/get_data`, params);
  },
  getPvWattsRateLimit() {
    return apiJSONClient().get("/sites/pvwatts/get_rate_limit");
  },
  getPvWattsRateRemaining() {
    return apiJSONClient().get("/sites/pvwatts/check_rate_remaining");
  },
  getSiteDataForProvider(provider, params) {
    return apiJSONClient().post(`/providers/${provider}/get_provider_data`, params);
  },
  getMissingStatsForProvider(provider, params) {
    return apiJSONClient().post(`/providers/${provider}/get_missing_stats`, params);
  },
  // Missing site kwh stats for the given user and date
  getMissingSiteStats(date) {
    return apiJSONClient().get(`/get_missing_site_stats?date=${date}`);
  },
  getPossibleSolutions(site_id) {
    return apiJSONClient().get(`/metrics/sites/${site_id}/possible_solutions`);
  },
  getMissingKwh(date) {
    return apiJSONClient().get(`/kwhestimates/missing?date=${date}`);
  },
  showOnlineSites() {
    return apiJSONClient().get(`/online_sites`);
  },
  uploadSiteData(file) {
    let formData = new FormData();
    formData.append("file", file);
    return apiUploaderClient().post(`/upload/sites/create`, formData)
  },
  uploadSiteDataV2(file, accountId) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("account_id", accountId);
    return apiUploaderClient().post(`/upload/sites/createv2`, formData)
  },
  uploadKwhData(file) {
    let formData = new FormData();
    formData.append("file", file);
    return apiUploaderClient().post(`/upload/sites/kwh`, formData)
  },
  getQuarterGroupProjections(reporting_group, type, year) {
    return apiJSONClient().get(`/upload/sites/get_group_qtr_proj?reporting_group=${reporting_group}&type=${type}&year=${year}`);
  },
  getYearGroupProjections(reporting_group, type, year) {
    return apiJSONClient().get(`/upload/sites/get_group_year_proj?reporting_group=${reporting_group}&type=${type}&year=${year}`);
  },
  getQuarterandYearProjections(reporting_group, type, year) {
    const promise1 = this.getQuarterGroupProjections(reporting_group, type, year);
    const promise2 = this.getYearGroupProjections(reporting_group, type, year)
    return Promise.all([promise1, promise2]);
  },
  uploadProjectionData(file) {
    let formData = new FormData();
    formData.append("file", file);
    return apiUploaderClient().post(`/upload/sites/projection`, formData)
  },
  getSiteProjectionsCsv(siteIds) {
    return apiJSONClient().post(`/site_projections/export_csv`, { site_ids: siteIds }, {
        responseType: "blob", // Ensures the response is treated as a binary blob
      }
    );
  },
  getDynSiteCount(site_group_id) {
    return apiJSONClient().get(`/rpt/dynsitecount?id=${site_group_id}`);
  },
  updateInverterFactor(inverterId, params) {
    return apiJSONClient().post(
      `/inverters/${inverterId}/factor/update`,
      params
    );
  },
  missingInverterTof() {
    return apiJSONClient().get("/inverters/missing_tof");
  },
  reloadInverterTof() {
    return apiJSONClient().get("/inverters/reload_tof");
  },
  missingSiteTof() {
    return apiJSONClient().get("/sites/metrics/missing_tof");
  },
  missingSiteGeo() {
    return apiJSONClient().get("/sites/metrics/missing_geom");
  },
  reloadSiteTof() {
    return apiJSONClient().get("/sites/metrics/reload_tof");
  },
  downloadSitesCSVTemplate () {
    return apiCSVClient().get('/download/sites/template')
  },
  downloadGmpCustomReport(quarter, year) {
    return apiZipClient().get(`/rpt/customgmpreport?year=${year}&quarter=${quarter}`)
  },
  downloadFilterCSVTemplate(name) {
    return apiCSVClient().get(`/api_filters_template?name=${name}`)
  },
  exportSitesCSVReport (from, until) {
    return apiCSVClient().get(`/export/sites/report?from=${from}&until=${until}`)
  },
  exportSitesCSVReportFiltered (params) {
    return apiJSONClient().post(`/export/sites/reportfiltered`, params)
  },
  // New reports
  getCustomerMetrics(id, token) {
    return apiJSONClient().get(`/singlecustomer/get_customer_metrics?site_id=${id}&token=${token}`)
  },
  getFleetStats (site_group_id, token, month, year) {
    return apiJSONClient().get(`/fleet/get_fleet_rpt?token=${token}&site_group_id=${site_group_id}&month=${month}&year=${year}`)
  },
  getFleetQtrInverterStats (site_group_id, token, month, year) {
    return apiJSONClient().get(`/fleet/get_fleet_inverter_qtr_rpt?token=${token}&site_group_id=${site_group_id}&month=${month}&year=${year}`)
  },
  getFleetQtrkWhSum (site_group_id, token, month, year) {
    return apiJSONClient().get(`/fleet/get_fleet_qtr_kwh_sum?token=${token}&site_group_id=${site_group_id}&month=${month}&year=${year}`)
  },
  getManySitesReport (reporting_group, month, year) {
    return apiJSONClient().get(`/rpt/reports/single_installer_rpt?reporting_group=${reporting_group}&month=${month}&year=${year}`)
  },
  monthlyReport (site_group_id, month, year) {
    return apiJSONClient().get(`/rpt/monthly?site_group_id=${site_group_id}&month=${month}&year=${year}`)
  },
  quarterlyReport (site_group_id, month, year) {
    return apiJSONClient().get(`/rpt/quarterly?site_group_id=${site_group_id}&month=${month}&year=${year}`)
  },
  getReportList() {
    return apiJSONClient().get("/rpt/get_report_list");
  },
  getRptSiteGroups() {
    return apiJSONClient().get("/rpt/get_rpt_site_group");
  },
  getRptFieldList() {
    return apiJSONClient().get("/rpt/get_rpt_field_list");
  },
  getIndividualReport (reporting_group, month, year) {
    return apiJSONClient().get(`/rpt/reports/mail_merge_rpt?month=${month}&year=${year}&reporting_group=${reporting_group}`)
  },
  getProviderInverterListCSVReport (provider) {
    return apiCSVClient().get(`/download/providers/${provider}/inverterlist`)
  },
  getProviderInverterListCSVReportNoFilter (provider) {
    return apiCSVClient().get(`/download/providers/${provider}/inverterlist`)
  },
  getProviderInverterListCSVReport (provider, filterId) {
    return apiCSVClient().get(`/download/providers/${provider}/inverterlist_filtered?filterId=${filterId}`)
  },
  getProviderSiteListCSVReportNoFilter (provider) {
    return apiCSVClient().get(`/download/providers/${provider}/sitelist_nofilter`)
  },
  //solaredge one
  getProviderSiteListCSVReport (provider, filterId) {
    return apiCSVClient().get(`/download/providers/${provider}/sitelist_filtered?filterId=${filterId}`)
  },
  getProviderIdSiteListCSVReport (provider, filterId) {
    return apiCSVClient().get(`/download/providers/${provider}/id_sitelist_filtered?filterId=${filterId}`)
  },
  uploadSitePlan(file, site_id) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("site_id", site_id);
    return apiUploaderClient().post(`/upload/sites/plan`, formData)
  },
  // inverters
  getInvertersGrid(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/inverters/grid`);
  },
  getInvertersGridAll(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/inverters/grid_all`);
  },
  getInvertersGridAllMaster(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/inverters/grid_all_master`);
  },
  getInverter(inverterId) {
    return apiJSONClient().get(`/inverters/${inverterId}`);
  },
  createInverter(siteId) {
    return apiJSONClient().post(`/sites/${siteId}/inverters/create`);
  },
  recastInverterEstimates(siteId) {
    const params = {}; // Add any required parameters if needed
    console.log(`Recasting estimates for site: ${siteId}`);
    return apiJSONClient().post(`/sites/${siteId}/recast-estimates`, params);
  },
  createInverterNew(siteId, params) {
    const inverter = { inverter: params }
    console.log(inverter)
    return apiJSONClient().post(`/sites/${siteId}/inverters/create`, inverter)
  },
  updateInverterPause(siteId, inverterId, params) {
    return apiJSONClient().post(`/sites/${siteId}/inverters/${inverterId}/update`, params);
  },
  updateInverter(inverterId, params) {
    return apiJSONClient().post(`/inverters/${inverterId}/update`, params);
  },
  updateInverterGrid(site_id, inverter_id, params) {
    const inverter = {
      "id": inverter_id,
      "inverter": params,
      "site_id": site_id
    }
    return apiJSONClient().post(`/sites/${site_id}/inverters/${inverter_id}/update_grid`, inverter)
  },
  deleteInverter(siteId, inverterId) {
    return apiJSONClient().delete(`/sites/${siteId}/inverters/${inverterId}`);
  },
  getFilters(monitoringSystem) {
    return apiCSVClient().get(`/api_filters?monitoring_system=${monitoringSystem}`)
  },
  deleteFilter(id) {
    return apiJSONClient().delete(`/api_filters/${id}`)
  },
  uploadInverterData (file) {
    let formData = new FormData();
    formData.append("file", file);

    return apiUploaderClient().post(`/upload/inverters/create`, formData)
  },
  downloadInvertersCSVTemplate () {
    return apiCSVClient().get('/download/inverters/template')
  },

  uploadSolaredgeFilterData (file, filterName, monitoringSystem) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("filterName", filterName)
    formData.append("monitoringSystem", monitoringSystem)

    return apiUploaderClient().post(`/upload/monitorapifilters/create`, formData)
  },
  // daily checks
  addCheck(userId, params) {
    return apiJSONClient().post(`user/${userId}/add_check`, params)
  },
  // site notes
  getNotes(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/notes/all`);
  },
  getCustomerNotes(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/customer_notes/all`);
  },
  // site cases
  getCasesBySiteId(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/cases/all`);
  },
  getCasesBySiteIdSc(siteId, token) {
    return apiJSONClient().get(`/singlecustomer/sites/${siteId}/token/${token}/cases/all`);
  },
  createNote(siteId, params) {
    return apiJSONClient().post(`/sites/${siteId}/notes/create`, params);
  },
  updateNote(siteId, noteId, params) {
    return apiJSONClient().post(`/sites/${siteId}/notes/${noteId}/update`, params);
  },
  deleteNote(siteId, noteId) {
    return apiJSONClient().delete(`/sites/${siteId}/notes/${noteId}`);
  },
  archiveNote(siteId, noteId, params) {
    return apiJSONClient().post(`/sites/${siteId}/notes/${noteId}/update`, params);
  },
  getArchived(siteId) {
    return apiJSONClient().get(`/sites/${siteId}/notes/archived`);
  },
  getNoteAttachments(noteId) {
    return apiJSONClient().get(`casenotes/${noteId}/attachments`);
  },
  uploadAttachment(file, case_note_id) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("case_note_id", case_note_id);
    return apiUploaderClient().post(`/upload/attachments/create`, formData)
  },
  // eslint-disable-next-line no-dupe-keys
  uploadAttachmentWithNotification(file, case_note_id) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("case_note_id", case_note_id);
    formData.append("send_notification", "true"); // Add the notification flag
    return apiUploaderClient().post(`/upload/attachments/create`, formData);
  },
  deleteCaseAttachement(case_attachment_id) {
    return apiJSONClient().delete(`/casenoteattachments/${case_attachment_id}`);
  },
  downloadCaseWorkOrderPDF(case_id, params) {
    return apiPDFClient().post(`/download/cases/${case_id}/reportpdf`, params)
  },
  // app settings
  getSettings() {
    return apiJSONClient().get('/app_settings');
  },
  saveSettings(params) {
    return apiJSONClient().post('/app_settings/save', params);
  },
  getMissingUpdateStats() {
    return apiJSONClient().get('/maint/missing_updates');
  },
  reloadMissingSiteStats(site_id) {
    return apiJSONClient().get(`/maint/reload_missing_site_stat_records?id=${site_id}`);
  },
  loadMissingInverterUpdates(site_id) {
    return apiJSONClient().get(`/maint/missing_inverter_updates?site_id=${site_id}`);
  },
  loadMissingInverterStats(site_id, date) {
    return apiJSONClient().get(`/sites/${site_id}/inverter_missing_kwh?date=${date}`);
  },
  getCompSchedule(id) {
    return apiJSONClient().get(`component_schedules/${id}`);
  },
  createCompSchedule(params) {
    return apiJSONClient().post('/component_schedules/create', params);
  },
  upsertCompSchedule(params) {
    return apiJSONClient().post('/component_schedules/upsert', params);
  },
  // payload
  // {
  //   "component_schedule": {
  //       "component_id": 796,
  //       "component_type_id": 1,
  //       "activate_date": "2023-06-20"
  //   }
  // }
  updateCompSchedule(id, params) {
    return apiJSONClient().put(`/component_schedules/${id}`, params);
  },
  deleteCompSchedule(id) {
    return apiJSONClient().delete(`/component_schedules/${id}`);
  },
  getCompScheduleByType(component_id, type_id) {
    return apiJSONClient().get(`component_schedules_by_type/${component_id}/type/${type_id}`);
  },
};
